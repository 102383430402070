.table {
    .cellWrapper {
      display: flex;
      align-items: center;
  
      .image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }
    }
  
    .status {
      padding: 5px;
      border-radius: 5px;
  
      &.Approved {
        color: green;
        background-color: rgba(0, 128, 0, 0.151);
      }
      &.Pending {
        color: goldenrod;
        background-color: rgba(189, 189, 3, 0.103);
      }
    }.cellAction {
      display: flex;
      align-items: center;
      gap: 15px;
  
      .viewButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: darkblue;
        border: 1px dotted rgba(0, 0, 139, 0.596);
        cursor: pointer;
      }
      
      .deleteButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: crimson;
        border: 1px dotted rgba(220, 20, 60, 0.6);
        cursor: pointer;
      }
    }
  }
  