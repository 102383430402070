.table {

  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.Approved {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.Pending {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
  } 
  
}.search {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.search input[type="text"] {
  flex-grow: 1;
  border: none;
  outline: none;
  margin-right: 5px;
}

.search .search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}


